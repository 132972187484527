import { parseValuation } from 'blog/type-file';
import dayjs from 'dayjs';
import { isNil } from 'lodash-es';
import type { RouterOutputs } from '../../../utils/trpc';
import { useUserAgent } from '../../../utils/userAgent';
import { constantMonthlyStatement } from '../edit/categorySelectOption';
import { getColdWaterItemLabelByConfig } from '../edit/getColdWaterItemLabelByConfig';
import { getOptionValueOrLabel } from '../edit/getOptionValueOrLabel';
import { timeDayFormatDefine } from '../overview/listConvertFunc';
import { calcFee } from '../util/calcFee';
import styles from './detail.module.css';
import { BlurComponent } from '../myInfo/BlurComponent';

// Big.prototype

// eslint-disable-next-line complexity
const Detail = ({
	config,
	content,
	noWidth,
	title,
}: {
	config: RouterOutputs['apartment']['details']['feeConfig'];
	content: any;
	noWidth?: boolean;
	title: string;
}) => {
	const { isPC, isMobile } = useUserAgent();

	const fee = config.find((i) => i.uuid === content.feeConfigSnapshotUuid);
	console.log(fee);
	const valuation = parseValuation(fee?.feeConfig);
	const detail = calcFee(content, valuation);

	if (!content) {
		return <div>无内容，请检查</div>;
	}

	return (
		<div className={`${noWidth ? '' : styles.center}`}>
			<div className={styles['the-detail']}>
				<div className={styles.title}>{title}</div>

				<div className={styles.layout}>
					<div className={styles['info-container']}>
						<div className={`${styles.description} `}>
							<div>房号: {detail.roomNumber}</div>

							<div>
								{detail.checkInTime && (
									<div>
										{dayjs(detail.checkInTime).format(timeDayFormatDefine)} 至
										{dayjs(detail.checkOutTime).format(timeDayFormatDefine)}
									</div>
								)}
							</div>
						</div>

						<table className={`${styles['print-table']} ${styles.table}`}>
							<tbody>
								<tr>
									<td className={styles.td}>项目名称</td>
									<td className={styles.td}>上月读数</td>
									<td className={styles.td}>本月读数</td>
									<td className={styles.td}>实用读数</td>
									<td className={styles.td}>计费单价</td>
									<td className={styles.td}>本月费用</td>
								</tr>

								<tr>
									<td className={styles.td}>房租</td>
									<td className={styles.td} />
									<td className={styles.td} />
									<td className={styles.td} />
									<td className={styles.td} />
									<td className={styles.td}>
										<BlurComponent>{detail.rent.toString()}</BlurComponent>
									</td>
								</tr>

								{detail.category !== constantMonthlyStatement && (
									<tr>
										<td className={styles.td}>押金</td>
										<td className={styles.td} />
										<td className={styles.td} />
										<td className={styles.td} />
										<td className={styles.td} />
										<td className={styles.td}>
											<BlurComponent>{detail.deposit.toString()}</BlurComponent>
										</td>
									</tr>
								)}

								{valuation.coldWaterFee.disabled === false && (
									<tr>
										<td className={styles.td}>
											{getColdWaterItemLabelByConfig(valuation) === ''
												? '水费'
												: '冷水费'}
										</td>
										<td className={styles.td}>
											{detail.coldWaterMeterReadingLastMonth.toString()}
										</td>
										<td className={styles.td}>
											{detail.coldWaterMeterReadingThisMonth.toString()}
										</td>
										<td className={styles.td}>
											<BlurComponent>
												{detail.totalColdWaterUsed.toString()}
											</BlurComponent>
										</td>
										<td className={styles.td}>
											<BlurComponent>
												{`${detail.coldWaterUnitPrice}${detail.valuation.coldWaterFee.unit}`}
											</BlurComponent>
										</td>
										<td className={styles.td}>
											<BlurComponent>
												{detail.totalColdWaterCharge.toString()}
											</BlurComponent>
										</td>
									</tr>
								)}

								{valuation.hotWaterFee.disabled === false && (
									<tr>
										<td className={styles.td}>热水费</td>
										<td className={styles.td}>
											{detail.hotWaterMeterReadingLastMonth.toString()}
										</td>
										<td className={styles.td}>
											{detail.hotWaterMeterReadingThisMonth.toString()}
										</td>
										<td className={styles.td}>
											<BlurComponent>
												{detail.totalHotWaterUsed.toString()}
											</BlurComponent>
										</td>
										<td className={styles.td}>
											<BlurComponent>
												{`${detail.hotWaterUnitPrice}${detail.valuation.hotWaterFee.unit}`}
											</BlurComponent>
										</td>
										<td className={styles.td}>
											<BlurComponent>
												{detail.totalHotWaterCharge.toString()}
											</BlurComponent>
										</td>
									</tr>
								)}

								{valuation.electricityFee.disabled === false && (
									<tr>
										<td className={styles.td}>电费</td>
										<td className={styles.td}>
											{detail.electricMeterReadingLastMonth.toString()}
										</td>
										<td className={styles.td}>
											{detail.electricMeterReadingThisMonth.toString()}
										</td>
										<td className={styles.td}>
											<BlurComponent>
												{detail.totalElectricUsed.toString()}
											</BlurComponent>
										</td>
										<td className={styles.td}>
											<BlurComponent>
												{`${detail.electricUnitPrice}${detail.valuation.electricityFee.unit}`}
											</BlurComponent>
										</td>
										<td className={styles.td}>
											<BlurComponent>
												{detail.totalElectricCharge.toString()}
											</BlurComponent>
										</td>
									</tr>
								)}

								{valuation.tvFee.disabled === false ||
									(detail?.tvFee.toNumber() > 0 && (
										<tr>
											<td className={styles.td}>电视费</td>
											<td className={styles.td} />
											<td className={styles.td} />
											<td className={styles.td} />
											<td className={styles.td}>
												<BlurComponent>{`${detail.valuation.tvFee.value}${detail.valuation.tvFee.unit}`}</BlurComponent>
											</td>
											<td className={styles.td}>
												<BlurComponent>{detail.tvFee.toString()}</BlurComponent>
											</td>
										</tr>
									))}

								{valuation.networkFee.disabled === false ||
									(detail?.networkFee.toNumber() > 0 && (
										<tr>
											<td className={styles.td}>网络费</td>
											<td className={styles.td} />
											<td className={styles.td} />
											<td className={styles.td} />
											<td className={styles.td}>
												<BlurComponent>
													{`${detail.valuation.networkFee.value}${detail.valuation.networkFee.unit}`}
												</BlurComponent>
											</td>
											<td className={styles.td}>
												<BlurComponent>
													{detail.networkFee.toString()}
												</BlurComponent>
											</td>
										</tr>
									))}
								{(valuation.cleanFee.disabled === false ||
									detail?.cleanFee.toNumber() > 0) && (
									<tr>
										<td className={styles.td}>卫生费</td>
										<td className={styles.td} />
										<td className={styles.td} />
										<td className={styles.td} />
										<td className={styles.td}>
											<BlurComponent>
												{`${detail.valuation.cleanFee.value}${detail.valuation.cleanFee.unit}`}
											</BlurComponent>
										</td>
										<td className={styles.td}>
											<BlurComponent>
												{detail.cleanFee.toString()}
											</BlurComponent>
										</td>
									</tr>
								)}

								<tr>
									<td
										colSpan={6}
										className={`${styles.td} ${styles['td-no-wrap']}`}
									>
										<div className={`${styles.description} ${styles.money}`}>
											<div>合计人民币大写(元): </div>
											<BlurComponent>
												{detail.capitalFormOfAmount} ({detail.amount.toString()}
												元 )
											</BlurComponent>
										</div>
									</td>
								</tr>
							</tbody>
						</table>

						<div className="flex flex-row">
							<div className={`${styles.description} ${styles.remark}`}>
								备注:
							</div>
							<BlurComponent
								className={`${styles.description} ${styles.remark}`}
							>
								{detail.remark}
							</BlurComponent>
						</div>
					</div>

					{isPC && (
						<div className={styles.uuid}>
							{detail.uuid.split('-').pop()}{' '}
							{!isNil(detail?.category) &&
								` · ${getOptionValueOrLabel(detail.category)}`}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default Detail;
